// -------------------------------------------------------------------------------------
// export const API_URL_IDENTITY_DOMAIN_KEY = 'http://localhost:63002/identity/GetPWDAccessToken';
// export const API_URL_IDENTITY_RENEW_KEY = 'http://localhost:63002/identity/renewToken';
// export const API_URL_DOMAIN_KEY = 'http://localhost:53170';
// -------------------------------------------------------------------------------------

// // -------------------------------------------------------------------------------------
// export const API_URL_IDENTITY_DOMAIN_KEY = 'https://identity.webinarsystems.ru/identity/GetPWDAccessToken';
// export const API_URL_IDENTITY_RENEW_KEY = 'https://identity.webinarsystems.ru/identity/renewToken';
// export const API_URL_DOMAIN_KEY = 'https://api.webinarsystems.ru';
// // -------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------
export const API_URL_IDENTITY_DOMAIN_KEY = 'https://identity.mindalay.net/identity/GetPWDAccessToken';
export const API_URL_IDENTITY_RENEW_KEY = 'https://identity.mindalay.net/identity/renewToken';
export const API_URL_DOMAIN_KEY = 'https://api.mindalay.net';
// -------------------------------------------------------------------------------------



export const API_URL_NODE_SERVER_DOMAIN_KEY = 'https://10.0.0.253:9001';